import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target", "template"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  };

  add(e) {
    e.preventDefault();

    const context = e.currentTarget.dataset.context;
    const template = this.templateTargets.filter(
      (t) => t.dataset.context === context,
    )[0];
    const target = this.targetTargets.filter(
      (t) => t.dataset.context === context,
    )[0];

    // console.log("context:", context);
    // console.log("template:", template);
    // console.log("target:", target);

    if (template && target) {
      const content = template.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime().toString(),
      );

      target.insertAdjacentHTML("beforebegin", content);
    }

    const event = new CustomEvent("rails-nested-form:add", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  remove(e) {
    e.preventDefault();

    const context = e.currentTarget.dataset.context;
    const wrapper = e.target.closest(this.wrapperSelectorValue);

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";

      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "1";
    }

    const event = new CustomEvent("rails-nested-form:remove", {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
}
