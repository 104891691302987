import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "genderTextarea",
    "workSituationTextarea",
    "relationshipToPrimaryTextarea",
    "childcareSituationTextarea",
  ];

  toggleTextAreaVisibility(event) {
    const checkbox = event.currentTarget;
    const index = checkbox.dataset.index || 0;

    const textAreaContainer = document.querySelector(
      '[data-attribute-name="' + checkbox.dataset.target + "-" + index + '"]'
    );

    const textArea = textAreaContainer.querySelector("textarea");
    textArea.value = "";
    if (checkbox.checked) {
      textAreaContainer.classList.remove("hidden");
    } else {
      textAreaContainer.classList.add("hidden");
    }
  }

  toggleGenderTextarea(event) {
    this.toggleTextAreaIfValue(
      this.genderTextareaTarget,
      event.target.value,
      "self_describe"
    );
  }

  toggleWorkSituationTextarea(event) {
    this.toggleTextAreaIfValue(
      this.workSituationTextareaTarget,
      event.target.value,
      "other"
    );
  }

  toggleRelationshipToPrimaryTextarea(event) {
    this.toggleTextAreaIfValue(
      this.relationshipToPrimaryTextareaTarget,
      event.target.value,
      "other"
    );
  }

  toggleChildcareSituationTextarea(event) {
    this.toggleTextAreaIfValue(
      this.childcareSituationTextareaTarget,
      event.target.value,
      "other"
    );
  }

  toggleTextAreaIfValue(target, selectedValue, otherValue) {
    let textAreaContainer = target.closest("div");

    if (selectedValue == otherValue) {
      textAreaContainer.classList.remove("hidden");
    } else {
      target.value = "";
      textAreaContainer.classList.add("hidden");
    }
  }
}
