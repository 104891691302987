import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currentStep", "totalSteps"]

  connect() {
  }

  setSteps(event) {
    const totalSteps = parseInt(event.currentTarget.dataset.totalSteps)
    // This value represents the previous value as submitted before turbo re-render
    const usePasses = event.currentTarget.dataset.usePasses

    if (usePasses == "true") {
      this.totalStepsTarget.textContent = totalSteps - 1
    } else {
      this.totalStepsTarget.textContent = totalSteps + 1
    }
  }
}
