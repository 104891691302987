import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target', 'template']


  add(event) {
    event.preventDefault()
    const clone = this.templateTarget.content.cloneNode(true)
    this.targetTarget.append(clone)
  }

  remove(event) {
    event.preventDefault();
    let wrapper = event.target.closest('.row')
    wrapper.remove()
  }
}