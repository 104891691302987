import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["characters", "cases", "symbols"];

  connect() {}

  onChange(event) {
    this.value = event.target.value;

    //more than 8 characters
    if (/^.{8,}$/.test(this.value)) {
      this.charactersTarget.querySelector("div").classList.add("hidden");
      this.charactersTarget.querySelector("img").classList.remove("hidden");
    } else {
      this.charactersTarget.querySelector("div").classList.remove("hidden");
      this.charactersTarget.querySelector("img").classList.add("hidden");
    }

    //one upper case and one lower case
    if (/^(?:(?=.*[a-z])(?=.*[A-Z]).*)$/.test(this.value)) {
      this.casesTarget.querySelector("div").classList.add("hidden");
      this.casesTarget.querySelector("img").classList.remove("hidden");
    } else {
      this.casesTarget.querySelector("div").classList.remove("hidden");
      this.casesTarget.querySelector("img").classList.add("hidden");
    }

    //one special character (~!@#£$%^&*()_-+={}[]|:;"',<>./?\)
    if (
      /^(?:(?=.*?[~`!@#£$%^&*_+=|:;"',<>./?\\\[\]\{\}\(\)\-]).*)$/.test(
        this.value
      )
    ) {
      this.symbolsTarget.querySelector("div").classList.add("hidden");
      this.symbolsTarget.querySelector("img").classList.remove("hidden");
    } else {
      this.symbolsTarget.querySelector("div").classList.remove("hidden");
      this.symbolsTarget.querySelector("img").classList.add("hidden");
    }
  }
}
