import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["turboFrameField", "button"]
  static values = { passId: String, valid: Boolean, turboFrame: String }

  clearErrors(event) {
    const errorElements = this.element.querySelectorAll(`p[data-error-attribute]`)
    const errorAttributes = this.errors(event)
    errorElements.forEach(element => {
      const i = errorAttributes.findIndex(e => e == element.dataset.errorAttribute)
      if (i > -1) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    });
  }

  errors(event) {
    const headers = event.detail.fetchResponse.response.headers
    let errors = []
    for (const pair of headers.entries()) {
      if (pair[0] == "errors") {
        errors = pair[1].split(" ")
      }
    }
    return errors
  }

  isSelected(event) {
    const headers = event.detail.fetchResponse.response.headers
    let selected = false
    for (const pair of headers.entries()) {
      if (pair[0] == "selected") {
        selected = pair[1] == "true"
      }
    }
    return selected
  }

  select(event) {
    if (this.isSelected(event)) {
      this.buttonTarget.classList.remove("btn-secondary")
      this.buttonTarget.classList.add("btn-tertiary")
      this.buttonTarget.value = "Selected"
    } else {
      this.buttonTarget.classList.remove("btn-tertiary")
      this.buttonTarget.classList.add("btn-secondary")
      this.buttonTarget.value = "Select guest pass"
    }
  }

  setTurboFrameField(event) {
    if (event.type == "focus") {
      this.turboFrameFieldTarget.value = ""
    } else if (event.type == "blur") {
      this.turboFrameFieldTarget.value = this.turboFrameValue
    }
  }

  setValid(event) {
    this.validValue = event.detail.success
  }
}
