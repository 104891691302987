import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["card", "error", "form", "terms", "scroll"];

  connect() {
    let stripeMeta = document.querySelector('meta[name="stripe-key"]');
    if (stripeMeta === null) {
      return;
    }

    let stripeKey = stripeMeta.getAttribute("content");
    this.stripe = Stripe(stripeKey);

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent");

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get("payment-intent");

    let appearance = {
      theme: "none",
      variables: {
        fontFamily:
          '"Sofia Pro", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
        colorBackground: "#FFF",
        fontSizeBase: "16px",
        borderRadius: "24px",
        colorText: "rgb(55 65 81 / 1)",
        fontWeightLight: "500",
        colorDanger: "#e53e3e",
      },
      rules: {
        ".Input": {
          color: "rgb(55 65 81 / 1)",
        },
        ".Input:focus": {
          borderColor: "#FFF",
          outline: "#FFF",
        },
        ".Label": {
          lineHeight: "20px",
          color: "rgb(55 65 81 / 1)",
        },
        ".Error": {
          marginTop: "4px",
          lineHeight: "1.5",
        },
      },
    };

    this.elements = this.stripe.elements({
      clientSecret: this.setup_intent,
      appearance,
    });

    // Setup regular payments
    const { name, email, phone, line1, line2, city, postalCode } =
      this.formTarget.dataset;
    const defaultValues = {
      defaultValues: {
        billingDetails: {
          name,
          email,
          phone,
          address: { line1, line2, city, postal_code: postalCode },
        },
      },
    };
    this.card = this.elements.create("payment", defaultValues);

    this.card.mount(this.cardTarget);
    this.card.addEventListener("change", this.changed.bind(this));
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message;
      this.scrollTarget.textContent = `You must scroll down and ${event.error.message}`;
    } else {
      this.errorTarget.textContent = "";
      this.scrollTarget.textContent = "";
    }
  }

  submit(event) {
    event.preventDefault();
    Rails.disableElement(this.formTarget);

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment();

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard();

      // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    }
  }

  setupNewCard() {
    let data = {
      elements: this.elements,
      redirect: "if_required",
    };
    this.stripe.confirmSetup(data).then((result) => {
      // this "modify_bacs_debit_bank_details" means the user clicks the close button or modify details action.
      if (
        result.error &&
        result.error.code != "modify_bacs_debit_bank_details"
      ) {
        this.showError(result.error.message);
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method);
      }
    });
  }

  handlePaymentMethod(payment_method_id) {
    this.addHiddenField("processor", "stripe");
    this.addHiddenField("payment_method_token", payment_method_id);
    this.formTarget.submit();
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", name);
    hiddenInput.setAttribute("value", value);
    this.formTarget.appendChild(hiddenInput);
  }

  showError(message) {
    this.errorTarget.textContent = message;
    this.scrollTarget.textContent = `You must scroll down and ${message.toLowerCase()}`;
    setTimeout(() => {
      Rails.enableElement(this.formTarget);
    }, 100);
  }
}
