import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
    console.log('Do what you want here.')

    this.counter = 1
  }

  add(event){
    super.add(event)

    this.counter += 1
    if(this.counter >= event.target.dataset.resourceCount){
      event.target.classList.add("hidden")
    }
  }
}
