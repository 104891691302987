import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["file", "button", "form"]

  connect() {
  }

  openFileDialogue(event) {
    event.preventDefault();
    this.fileTarget.click();
  }

  submitForm(event) {
    event.preventDefault();
    debugger
    this.formTarget.submit();
  }
}
