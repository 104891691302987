import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target", "template", "button", "form"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
    maximumRecords: {
      type: Number,
      default: 1,
    },
    initialRecordCount: {
      type: Number,
      default: 1,
    },
    recordCountSelector: {
      type: String,
      default: ".nested-form-count-wrapper",
    },
  };

  connect() {
    this.recordCount = Math.max(this.initialRecordCountValue, 1);
    this.toggleAddButtonVisibility();
  }

  add(event) {
    event.preventDefault();

    const clone = this.templateTarget.content.cloneNode(true);
    const wrapper = clone.querySelector(this.wrapperSelectorValue);

    wrapper.querySelector(".remove").classList.remove("hidden");
    wrapper.dataset.itemNumber = this.getNextItemNumber(); // Add item count to dataset
    wrapper.querySelector(this.recordCountSelectorValue).innerHTML =
      this.getNextItemNumber(); // Add item count to header
    wrapper.innerHTML = wrapper.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    ); // Add timestamp

    this.targetTarget.append(wrapper);
    this.toggleAddButtonVisibility();
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-form-wrapper");

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }

    this.updateItemsNumbers(wrapper.dataset.itemNumber);
    this.toggleAddButtonVisibility();
  }

  getAllItems() {
    return [...document.getElementsByClassName("nested-form-wrapper")];
  }

  getItemsNumbers() {
    let elements = this.getAllItems();

    let itemsNumbers = [];

    elements.forEach((element) => {
      itemsNumbers.push(element.dataset.itemNumber);
    });

    return itemsNumbers;
  }

  getNextItemNumber() {
    return Math.max.apply(Math, this.getItemsNumbers()) + 1;
  }

  updateItemsNumbers(fromNumber) {
    let elements = this.getAllItems();

    elements.forEach((element) => {
      if (element.dataset.itemNumber > fromNumber) {
        let newNumber = element.dataset.itemNumber - 1;
        element.dataset.itemNumber = newNumber;
        element.querySelector(this.recordCountSelectorValue).innerHTML =
          newNumber;
      }
    });
  }

  toggleAddButtonVisibility() {
    let itemsAddedCount = this.getAllItems().length;

    if (itemsAddedCount >= this.maximumRecordsValue) {
      this.buttonTarget.classList.add("hidden");
    } else {
      this.buttonTarget.classList.remove("hidden");
    }
  }
}
