import { Controller } from "@hotwired/stimulus"
import Honeybadger from "@honeybadger-io/js"

export default class extends Controller {
  static values = { env: String, userId: String }

  initialize() {
    Honeybadger.configure({
      apiKey: 'hbp_ZCDx1Xf9mdgoHJZTXz8m6zVPbRAjtr1tPRe2',
      environment: this.envValue
    });

    Honeybadger.setContext({
      user_id: this.userIdValue,
    });
  }
}
