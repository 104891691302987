import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["hidden", "input"]

  connect() {
  }

  convert() {
    this.hiddenTarget.value = this.inputTarget.value * 100;
  }
}
