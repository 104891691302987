import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["modalOpenBtn"];
  static values = { path: String };

  connect() {
    this.element.addEventListener("show.bs.offcanvas", this.show.bind(this));
    this.element.addEventListener("shown.bs.offcanvas", this.shown.bind(this));
    this.element.addEventListener("hide.bs.offcanvas", this.hide.bind(this));
    this.element.addEventListener(
      "hidden.bs.offcanvas",
      this.hidden.bind(this)
    );
  }

  disconnect() {}

  show(event) {
    console.log("offcanvas show");
  }

  shown(event) {
    console.log("offcanvas shown");
  }

  hide(event) {
    console.log("offcanvas hide");
  }

  hidden(event) {
    console.log("offcanvas hidden");

    if (this.pathValue) {
      Turbo.visit(this.pathValue);
    }
  }

  showHiddenModal(event) {
    event.preventDefault();
    this.modalOpenBtnTarget.click();
  }
}
