import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "fields" ]
  static values = {
    referenceField: String,
    referenceValue: String,
    toggleFields: Array
  }

  connect() {
    this.targetedField().addEventListener('change', this.toggleFields.bind(this))
    this.toggleFields()
  }

  disconnect() {
    this.targetedField().removeEventListener('change', this.toggleFields)
  }

  targetedField(){
    return this.element.querySelector(`[data-name=${this.referenceFieldValue}]`)
  }

  toggleFields() {
    const show_field = this.targetedField().value == this.referenceValueValue
    this.toggleFieldsValue.forEach(data_name => {
      const elem = this.element.querySelector(`[data-name=${data_name}]`)
      const parent = elem.closest('.field-unit')
      if (show_field) {
        parent.classList.remove('hidden')
      } else {
        elem.value = null
        parent.classList.add('hidden')
      }
    });
  }
}