import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "selectedDate",
    "selectedDuration",
    "startsAt",
    "endsAt",
    "newStep",
    "serviceSessionId",
  ];
  static values = {};

  initialize() {}

  selectDate(event, force = false) {
    Rails.fire(this.element, "submit");
  }

  selectDuration(event) {
    Rails.fire(this.element, "submit");
  }

  setStartsAt(event) {
    const starts_at = event.currentTarget.dataset["startsAt"];

    this.startsAtTarget.value = starts_at;
  }

  setEndsAt(event) {
    const ends_at = event.currentTarget.dataset["endsAt"];

    this.endsAtTarget.value = ends_at;
  }

  setServiceSessionId(event) {
    const service_session = event.currentTarget.dataset["serviceSessionId"];
    this.serviceSessionIdTarget.value = service_session;
  }

  setSessionBookingDetails(event) {
    event.preventDefault();

    this.setStartsAt(event);
    this.setEndsAt(event);
    this.setServiceSessionId(event);

    Rails.fire(this.element, "submit");
  }

  changeStep(event) {
    event.preventDefault();

    const selected_date = event.currentTarget.dataset["date"];
    this.newStepTarget.value = selected_date;

    Rails.fire(this.element, "submit");
  }
}
