import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "goToNextStep",
    "checklist",
    "guestList",
    "guestTickets",
    "dropOffUserId",
    "pickUpUserId",
  ];

  static values = { minCount: Number };

  initialize() {}

  connect() {
    if (this.hasChecklistTarget) {
      this.limitCheckboxesSelectable();
    }
  }

  clearError(event) {
    let wrapper = event.target.closest(".form-group");

    let errorMsg = wrapper.querySelector("p");
    if (errorMsg != null) {
      wrapper.querySelector("p").classList.add("hidden");
    }
  }

  checkboxAutoSubmit(event) {
    event.preventDefault();
    if (this.hasChecklistTarget) {
      this.limitCheckboxesSelectable(event);
    }
    this.goToNextStepTarget.value = false;
    Rails.fire(this.element, "submit");
  }

  limitCheckboxesSelectable(event) {
    let maxResources = this.checklistTarget.dataset.maxResources;
    let remainingCapacity = this.checklistTarget.dataset.remainingCapacity;

    let checkboxes = this.checklistTarget.querySelectorAll(
      "input[type=checkbox][data-max-selection=true]",
    );

    let selectedCheckboxes = this.checklistTarget.querySelectorAll(
      "input[type=checkbox][data-max-selection=true]:checked",
    );

    let unselectedCheckboxesCount =
      checkboxes.length - selectedCheckboxes.length;

    if (
      remainingCapacity < maxResources &&
      remainingCapacity <= unselectedCheckboxesCount
    ) {
      if (remainingCapacity <= 0) {
        checkboxes.forEach(this.addDisabledClass);
        event.target.checked = false;
      } else if (remainingCapacity > 0) {
        checkboxes.forEach(this.removeDisabledClass);
      }
    } else {
      if (selectedCheckboxes.length > maxResources) {
        event.target.checked = false;
      } else if (selectedCheckboxes.length == maxResources) {
        checkboxes.forEach(this.addDisabledClass);
      } else if (selectedCheckboxes.length < maxResources) {
        checkboxes.forEach(this.removeDisabledClass);
      }
    }
  }

  setGuestTickets(event) {
    event.preventDefault();

    this.guestTicketsTarget.value = event.target.getAttribute("data-value");

    let selectedCheckboxes = this.guestListTarget.querySelectorAll(
      "input[type=checkbox]:checked",
    );

    selectedCheckboxes.forEach((item) => {
      item.checked = false;
    });

    event.target.closest("div").querySelector("input[type=checkbox]").checked =
      true;

    this.goToNextStepTarget.value = false;
    Rails.fire(this.element, "submit");
  }

  setDropOffUser(event) {
    this.dropOffUserIdTarget.value = event.target.getAttribute("data-userId");

    let selectedCheckboxes = document
      .getElementById("dropOffList")
      .querySelectorAll("input[type=checkbox]:checked");

    selectedCheckboxes.forEach((item) => {
      item.checked = false;
    });

    event.target.closest("div").querySelector("input[type=checkbox]").checked =
      true;
  }

  setPickUpUser(event) {
    this.pickUpUserIdTarget.value = event.target.getAttribute("data-userId");

    let selectedCheckboxes = document
      .getElementById("pickUpList")
      .querySelectorAll("input[type=checkbox]:checked");

    selectedCheckboxes.forEach((item) => {
      item.checked = false;
    });

    event.target.closest("div").querySelector("input[type=checkbox]").checked =
      true;
  }

  addDisabledClass(item) {
    if (item.checked === false) {
      item.closest("div").classList.add("disabled");
    }
  }

  removeDisabledClass(item) {
    item.closest("div").classList.remove("disabled");
  }

  onlyAllowNumbers(event) {
    if (isNaN(event.data)) {
      event.target.value = event.target.value.replace(event.data, "");
    }
  }

  togglePlanAddOns(event) {
    let planId = event.target.value;
    let addOnGroups = document.querySelectorAll(".add-on-checkbox");
    let selectedPlanAddOnGroup = document.getElementById(planId);

    addOnGroups.forEach(this.addHiddenClass);

    addOnGroups.forEach((group) => {
      let selectedCheckboxes = group.querySelectorAll(
        "input[type=checkbox]:checked",
      );
      selectedCheckboxes.forEach((item) => {
        item.checked = false;
      });
    });

    this.removeHiddenClass(selectedPlanAddOnGroup);
  }

  addHiddenClass(item) {
    item.classList.add("hidden");
  }

  removeHiddenClass(item) {
    if (item) {
      item.classList.remove("hidden");
    }
  }
}
