import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {}

  connect() {
    this.clearToast();
  }

  clearToast() {
    if (this.timerid) {
      clearTimeout(this.timerid);
    }

    this.timerid = setTimeout(() => {
      this.element.animate({ opacity: "0" }, 500);
    }, 4500);

    this.timerid = setTimeout(() => {
      this.element.classList.add("hidden");
    }, 5000);
  }

  close(event) {
    event.preventDefault();
    this.element.classList.add("hidden");
  }
}
