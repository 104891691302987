import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "isAddCard" ]

  connect() {
    let hasSelected = false

    this.cards().forEach((card) => {
      card.dataset["action"] = "click->select-card#onChoose"

      if(card.dataset.selected == "true"){
        this.input().value = card.dataset.pm
        hasSelected = true
      }
    })

    if(hasSelected == false){
      this.submitBtn().setAttribute("disabled", "disabled") 
    }
  }

  onChoose(event){
    let isExpired = event.currentTarget.dataset.expired

    if(isExpired == "false"){
      this.cards().forEach((card) => {
        if(card.dataset.selected == "true"){
          this.deactivateCard(card)
        }

        if(card == event.currentTarget){
          this.activatecard(card)
        }
      })
    }
  }

  onAddCard(event){
    this.isAddCardTarget.value = true
  }

  cards() {
    return this.element.querySelectorAll(".credit-card");
  }

  input(){
    return this.element.querySelector("input[type='text']")
  }

  submitBtn() {
    return this.element.querySelector("#submit_card")
  }

  deactivateCard(card){
    card.classList.remove("border-lhg-sage-500", "bg-lhg-sage-400")
    card.classList.add("border-lhg-cream-500", "bg-transparent")
    card.dataset.selected = "false"
  }

  activatecard(card){
    card.classList.remove("border-lhg-cream-500", "bg-transparent")
    card.classList.add("border-lhg-sage-500", "bg-lhg-sage-400")
    card.dataset.selected = "true"

    this.input().value = card.dataset.pm

    this.submitBtn().removeAttribute("disabled")
  }
}