import Reveal from "stimulus-reveal-controller";

export default class extends Reveal {
  static targets = ["total", "info", "chevronDown", "chevronUp"];

  connect() {
    super.connect();
  }

  toggleSummaryDetails() {
    this.totalTarget.classList.toggle("hidden");
    this.infoTarget.classList.toggle("hidden");
  }

  toggleChevron() {
    this.chevronUpTarget.classList.toggle("hidden");
    this.chevronDownTarget.classList.toggle("hidden");
  }
}
