import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["planInput", "addOnSelection", "errorMessage", "errorContainer", "form", 'submitButton']

  initialize() {
    this.selectedAddOns = []
  }

  connect() {
    this.updateSelectValues();
    this.updateSelectedAddOns();
  }

  planChanged(_event) {
    this.removeAddOns()
    this.updateSelectValues()
  }

  removeAddOns() {
    this.addOnSelectionTargets.forEach((addOnSelection) => {
      // click only the remove button if its a existing add on.
      if(addOnSelection.dataset.newRecord == 'false') {
        addOnSelection.querySelector('button').click()
        addOnSelection.querySelector('select').removeAttribute('required')
      } else {
        addOnSelection.remove()
      }
    })
  }

  updateSelectValues() {
    this.addOnSelectionTargets.forEach((addOnSelection) => {
      this.updateSelectOptions(addOnSelection)
    })
  }

  updateSelectOptions(addOnSelection) {
    const selected = addOnSelection.querySelector('option[selected="selected"]')
    addOnSelection.querySelectorAll('option').forEach((elem) => {
      if(elem.dataset['planId'] !== this.planInputTarget.value) {
        elem.remove()
      }
    })
    addOnSelection.querySelector('select').value = selected ? selected.value : ""
  }

  updateSelectedAddOns(_event) {
    let hasDuplicate = false
    let selectedAddOns = []
    this.addOnSelectionTargets.forEach((addOnSelection) => {
      const value = addOnSelection.querySelector('select').value
      if(selectedAddOns.indexOf(value) < 0) {
        if(value != "") {
          selectedAddOns.push(value)
        }
      } else {hasDuplicate = true}
    })
    if(hasDuplicate) {
      this.errorContainerTarget.classList.remove('hidden')
      this.errorMessageTarget.innerHTML= 'Duplicate Add On'
      this.submitButtonTarget.setAttribute('disabled', '')
    } else {
      this.errorContainerTarget.classList.add('hidden')
      this.errorMessageTarget.innerHTML = ''
      this.submitButtonTarget.removeAttribute('disabled', '')
    }
  }

  updateLastSelectValues() {
    const addOnSelection = this.addOnSelectionTargets[this.addOnSelectionTargets.length - 1]
    this.updateSelectOptions(addOnSelection)
    this.updateSelectedAddOns()
  }
}
